import { workItem } from "@fscrypto/domain";
import {
  DashboardCreated,
  DashboardUpdatedPersistSuccess,
  QueryCreated,
  QueryUpdated,
  WorkItemEvent,
} from "@fscrypto/domain/events";
import * as Events from "@fscrypto/domain/events";
import { WorkItemType } from "@fscrypto/domain/work-item";
import { Client } from "@fscrypto/http";
import {
  AsyncStore,
  Entity,
  EntityFactory,
  EventBus,
  PersistentStore,
  Store,
  createAsyncStore,
  createPersistentStore,
  createStore,
  useEntity,
  useObservableValue,
  useOptionalStore,
} from "@fscrypto/state-management";
import { keyBy } from "lodash-es";
import { useObservable, useObservableState } from "observable-hooks";
import { useCallback, useEffect } from "react";
import { combineLatest, filter, map, switchMap } from "rxjs";
import { GlobalEvent as LegacyGlobalEvent, v2 as eventBus, eventBus as legacyEventBus } from "~/state/events";
import { WorkItemClient } from "../data/work-item-client";

type GlobalEvents = WorkItemEvent | QueryCreated | QueryUpdated | DashboardCreated | DashboardUpdatedPersistSuccess;

class WorkItem implements Entity<workItem.WorkItem> {
  public readonly id: string;
  store: PersistentStore<workItem.WorkItem>;
  constructor(
    private client: WorkItemClient,
    private eventBus: EventBus<GlobalEvents>,
    private legacyEventBus: EventBus<LegacyGlobalEvent>,
    initialValue: workItem.WorkItem,
  ) {
    this.id = initialValue.id;
    this.store = createPersistentStore(initialValue, (q) => this.client.update(this.id, q), 500);
    this.store.start();

    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "QUERY.UPDATED":
          if (e.payload.id === this.id && this.store.get().name !== e.payload.name) {
            this.store.set({ ...this.store.get(), name: e.payload.name }, false);
          }
          break;
        case "DASHBOARD.UPDATED.PERSIST.SUCCESS": {
          if (e.payload.id === this.id && this.store.get().name !== e.payload.title) {
            this.store.set({ ...this.store.get(), name: e.payload.title }, false);
          }
          break;
        }
      }
    });

    this.legacyEventBus.events$.pipe(filter((e) => e.type === "GLOBAL.DASHBOARD.SET_TITLE")).subscribe((e) => {
      if (e.type !== "GLOBAL.DASHBOARD.SET_TITLE") return;
      if (e.dashboardId !== this.id) return;
      this.store.set({ ...this.store.get(), name: e.payload }, false);
      this.eventBus.send(Events.workItems.renamed(this.store.get()));
    });
  }

  async move(workItem: workItem.WorkItem) {
    const updated = await this.client.move(workItem);
    this.store.set({ ...this.store.get(), ...updated }, false);
    this.eventBus.send(Events.workItems.moved(updated));
  }

  async remove() {
    const item = this.store.get();
    await this.client.delete(item);
    this.eventBus.send(Events.workItems.removed(item));
  }

  async fork() {
    const item = this.store.get();
    const forked = await this.client.fork(item);
    this.eventBus.send(Events.workItems.forked(forked));
    return forked;
  }

  async updateName(name: string) {
    this.store.set({ ...this.store.get(), name });
    this.eventBus.send(Events.workItems.updated(this.store.get()));
    const item = this.store.get();
    if (item.typename === "dashboard" && item.version === "2") {
      this.legacyEventBus.send({
        type: "GLOBAL.WORK_ITEM.RENAME",
        payload: { id: item.id, name, typename: item.typename },
      });
    }
  }
}

export class WorkItemFactory implements EntityFactory<WorkItem> {
  public workItems: Store<Record<string, WorkItem>> = createStore({});
  public recentlyOpened: Store<string[]> = createStore([] as string[]);
  public recentVisualizations: AsyncStore<string[]> = createAsyncStore(this.getRecentVisualizations.bind(this), []);
  public filteredVisualizations: AsyncStore<string[]> = createAsyncStore(this.findVisualizations.bind(this), []);

  constructor(
    private readonly client: WorkItemClient,
    private readonly eventBus: EventBus<GlobalEvents>,
    private readonly legacyEventBus: EventBus<LegacyGlobalEvent>,
  ) {
    this.eventBus.events$.subscribe(async (e) => {
      switch (e.type) {
        case "QUERY.CREATED":
          const data = await this.client.getByIdAndType(e.payload.id, "query");
          const workItem = new WorkItem(this.client, this.eventBus, this.legacyEventBus, data);
          this.workItems.set({ ...this.workItems.get(), [e.payload.id]: workItem });
          this.recentlyOpened.set([e.payload.id, ...this.recentlyOpened.get()]);
          break;
        case "DASHBOARD.CREATED":
          const dashboard = await this.client.getByIdAndType(e.payload.id, "dashboard");
          const workItemDashboard = new WorkItem(this.client, this.eventBus, this.legacyEventBus, dashboard);
          this.workItems.set({ ...this.workItems.get(), [e.payload.id]: workItemDashboard });
          this.recentlyOpened.set([e.payload.id, ...this.recentlyOpened.get()]);
          break;
        case "WORK_ITEM.REMOVED":
          const item = e.payload;
          const workItems = this.workItems.get();
          delete workItems[item.id];
          this.workItems.set({ ...workItems });
          break;
        case "WORK_ITEM.FORKED":
          const forked = e.payload;
          const forkedItem = new WorkItem(this.client, this.eventBus, this.legacyEventBus, forked);
          this.workItems.set({ ...this.workItems.get(), [forked.id]: forkedItem });
          break;
      }
    });
  }

  from(w: workItem.WorkItem): WorkItem {
    const workItem = new WorkItem(this.client, this.eventBus, this.legacyEventBus, w);
    this.workItems.set({ ...this.workItems.get(), [w.id]: workItem });
    return workItem;
  }

  fromMany(ws: workItem.WorkItem[]): WorkItem[] {
    const workItems = ws.map((w) => new WorkItem(this.client, this.eventBus, this.legacyEventBus, w));
    const indexed = keyBy(workItems, "id");
    this.workItems.set({ ...this.workItems.get(), ...indexed });
    return workItems;
  }

  from$(id: string) {
    return this.workItems.value$.pipe(
      map((w) => w[id]),
      filter(Boolean),
    );
  }

  fromAll$() {
    return this.workItems.value$.pipe(
      map((w) => Object.values(w).map((w) => w.store)),
      filter(Boolean),
    );
  }

  async getRecentlyOpened(type?: string) {
    const workItems = await this.client.getRecent(type);
    const entities = this.fromMany(workItems);
    this.recentlyOpened.set(entities.map((e) => e.id));
  }

  async getRecentVisualizations(type?: string) {
    const workItems = await this.client.getRecent(type);
    const entities = this.fromMany(workItems);
    this.recentVisualizations.set(entities.map((e) => e.id));
    return workItems.map((w) => w.id);
  }

  async findVisualizations(name: string) {
    const workItems = await this.client.filter(name, "visualization");
    const entities = this.fromMany(workItems);
    this.filteredVisualizations.set(entities.map((e) => e.id));
    return workItems.map((w) => w.id);
  }

  addRecentlyUsed(id: string) {
    const newItems = new Set([id, ...this.recentlyOpened.get()]);
    this.recentlyOpened.set(Array.from([...newItems]));
  }

  async getByParentId(parentId?: string) {
    const workItems = await this.client.get(parentId);
    return this.fromMany(workItems);
  }

  async create(newWorkItem: workItem.WorkItemNew) {
    const workItem = await this.client.create(newWorkItem);
    return this.from(workItem);
  }

  async getById(id: string) {
    const existing = this.workItems.get()[id];
    if (existing) return existing;

    const workItem = await this.client.getByIdAndType(id);
    const entity = this.from(workItem);
    return entity;
  }

  async search(searchTerm: string, type?: WorkItemType) {
    const workItems = await this.client.filter(searchTerm, type);
    const workItemEntities = this.fromMany(workItems);
    return workItemEntities;
  }
}

export const workItemFactory = new WorkItemFactory(
  new WorkItemClient(new Client()),
  eventBus.eventBus as EventBus<GlobalEvents>,
  legacyEventBus as EventBus<LegacyGlobalEvent>,
);

export const useWorkItems = () => {
  const stores$ = useObservable(() => workItemFactory.fromAll$());
  const values$ = useObservable(() => stores$.pipe(switchMap((ss) => combineLatest(ss.map((s) => s.value$)))));
  const items = useObservableState(values$, []);

  return {
    items,
    addToRecent: (id: string) => {
      workItemFactory.addRecentlyUsed(id);
    },
    search: workItemFactory.search.bind(workItemFactory),
  };
};

export const useWorkItem = (id: string) => {
  const entity = useEntity(workItemFactory, id);
  const workItem = useOptionalStore(entity?.store);
  if (!workItem || !entity) return null;
  return {
    workItem,
    remove: entity.remove.bind(entity),
    fork: entity.fork.bind(entity),
  };
};

export const useRecentlyOpened = () => {
  const items = useObservableState(workItemFactory.recentlyOpened.value$, workItemFactory.recentlyOpened.get());
  const recentWorkItems = items
    ?.filter((id) => workItemFactory.workItems.get()[id])
    ?.map((id) => workItemFactory.workItems.get()[id].store.get());
  return {
    items,
    recentWorkItems,
    load: () => {
      return workItemFactory.getRecentlyOpened();
    },
  };
};

export const useRecentVisualizations = () => {
  const latestIds = useObservableValue(workItemFactory.recentVisualizations.value$);
  const isLoading = useObservableValue(workItemFactory.recentVisualizations.loading$);
  useEffect(() => {
    workItemFactory.recentVisualizations.fetchData("visualization");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { latestIds: latestIds ?? [], isLoading };
};

export const useFindVisualizations = () => {
  const ids = useObservableValue(workItemFactory.filteredVisualizations.value$);
  const isLoading = useObservableValue(workItemFactory.filteredVisualizations.loading$);

  const reset = useCallback(() => {
    workItemFactory.filteredVisualizations.set([]);
  }, []);

  return {
    visIds: ids ?? [],
    isLoading,
    findVisualizations: workItemFactory.filteredVisualizations.fetchData,
    reset,
  };
};
