import { tagsClient } from "./tags-client";

/** Get list of tag IDs, creating tags if needed */
export const getTagIds = async (tags: { name: string }[]): Promise<string[]> => {
  const tagIds: string[] = await Promise.all(
    tags.map(async (tag) => {
      let id = "id" in tag ? tag.id : undefined;
      if (!id) {
        const newTag = await tagsClient.create({ name: tag.name });
        id = newTag.id;
      }

      return id as string;
    }),
  );

  return tagIds;
};
